<template>
	<v-app>
		<v-data-table
			:headers="headers"
			:items="places"
			:hide-default-footer="false"
			:items-per-page="50"
			sort-by="id"
			@click:row="handleClick"
			:footer-props = "{ 'items-per-page-options':[15, 30, 50, 100, -1], 'items-per-page-text' : '行/ページ:' }"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>地点一覧</v-toolbar-title
					><v-btn class="add" @click.stop="newItem">地点を追加</v-btn>
				</v-toolbar>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="edit" @click="editItem(item)">
					mdi-pencil
				</v-icon>
			</template>
		</v-data-table>

		<v-footer>
			©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
	</v-app>
</template>

<script>
import axios from "axios";

export default {
	components: {},
	data: () => ({
		isLoading: false,
		headers: [
			{ text: "名称", value: "name", class: "name" },
			{ text: "ID", align: "start", value: "id" },
			{ text: "住所", value: "address" },
			{ text: "緯度", value: "latitude" },
			{ text: "経度", value: "longitude" },
			{ text: "最終更新日時", value: "createdAt" },
			{ text: "", value: "actions", sortable: false },
		],
		editedIndex: -1,
		places: [],
	}),
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
		this.initialize();
	},
	methods: {
		initialize() {
			this.showLoading();
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/admin-places"
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.places = res.data.places;
						console.log("Success: " + JSON.stringify(res.data));
					} else {
						this.hideLoading();
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		editItem(item) {
			this.$router
				.push({ name: "AdminEditPlace", params: { id: item.id } })
				.catch(() => {});
		},
		newItem() {
			this.$router.push({ name: "AdminEditPlace" }).catch(() => {});
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
		handleClick(value, e) {
			console.log(e.index);
			e.select(!e.isSelected);
		},
	},
};
</script>

